export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
        },
      },
    },
  };
}
