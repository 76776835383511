import Loadable from "app/components/Loadable";
import { lazy } from "react";

export const Home = Loadable(lazy(() => import("./Home")));
export const Users = Loadable(lazy(() => import("../management/Users/Users")));
export const UsersForm = Loadable(
  lazy(() => import("../../components/Forms/User/UserForm"))
);
export const Stores = Loadable(
  lazy(() => import("../management/Stores/Stores"))
);
export const StoresForm = Loadable(
  lazy(() => import("../../components/Forms/Store/StoreForm"))
);
export const Faq = Loadable(lazy(() => import("../management/Faq/Faq")));
export const FaqForm = Loadable(
  lazy(() => import("../../components/Forms/Faq/FaqForm"))
);
export const Prizes = Loadable(
  lazy(() => import("../management/Prizes/Prizes"))
);
export const PrizeForm = Loadable(
  lazy(() => import("../../components/Forms/Prize/PrizeForm"))
);
export const Benefits = Loadable(
  lazy(() => import("../management/Benefits/Benefits"))
);
export const BenefitForm = Loadable(
  lazy(() => import("../../components/Forms/Benefit/BenefitForm"))
);
export const Winners = Loadable(
  lazy(() => import("../management/Winners/Winners"))
);
export const Screens = Loadable(
  lazy(() => import("../management/Screens/Screens"))
);
export const ScreenForm = Loadable(
  lazy(() => import("../../components/Forms/Screens/ScreenFormContainer"))
);
export const Clients = Loadable(
  lazy(() => import("../management/Clients/Clients"))
);
export const ClientForm = Loadable(
  lazy(() => import("../../components/Forms/Client/ClientForm"))
);
export const Menu = Loadable(lazy(() => import("../management/Menu/Menu")));
export const Offers = Loadable(
  lazy(() => import("../management/Offers/Offers"))
);
export const OfferForm = Loadable(
  lazy(() => import("../../components/Forms/Offer/OfferForm"))
);
export const Messages = Loadable(
  lazy(() => import("../management/Messages/Messages"))
);
export const MessageForm = Loadable(
  lazy(() => import("../../components/Forms/Messages/MessageForm"))
);
export const ProfileForm = Loadable(
  lazy(() => import("../../components/Forms/Profile/ProfileForm"))
);
