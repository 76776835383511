import { CssBaseline, ThemeProvider } from "@mui/material";
import muiTheme from "app/theme/muiTheme";
import ComponentsOverrides from "app/theme/overrides";

const MatxTheme = ({ children }) => {
  const theme = muiTheme();
  theme.components = ComponentsOverrides(theme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
