export const navigations = [
  { name: "Home", path: "/home", icon: "dashboard" },
  { name: "Usuários", path: "/usuarios", icon: "group_icon" },
  { name: "Clientes", path: "/clientes", icon: "handshake_icon " },
  { name: "Lojas", path: "/lojas", icon: "store_icon" },
  { name: "FAQ", path: "/faqs", icon: "quiz_icon" },
  { name: "Prêmios", path: "/premios", icon: "emoji_events_icon" },
  { name: "Benefícios", path: "/beneficios", icon: "loyalty_icon" },
  { name: "Ganhadores", path: "/ganhadores", icon: "military_tech_icon " },
  { name: "Ofertas", path: "/ofertas", icon: "local_offer_icon " },
  { name: "Mensagens", path: "/mensagens", icon: "message_icon " },

  { label: "Config. App", type: "label" },
  { name: "Telas", path: "/telas", icon: "smartphone_icon " },
  {
    name: "Menu Principal",
    path: "/menu-principal",
    icon: "list_icon ",
  },
  {
    name: "Menu Rodapé",
    path: "/menu-footer",
    icon: "border_bottom_icon ",
  },
];
