export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        root: {},
      },
    },
  }
}
