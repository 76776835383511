import Endpoints from "app/config/Endpoints";
import {
  UserLoginFields,
  IDataUserLogin,
  IHookFormUserLogin,
  IDataUserLoginErrors,
  IDataResetPassword,
} from "app/types/data/IUserAuth";
import { AxiosError } from "axios";
import Api from "./api";
import Model_Api, { IDataErrors } from "./Model_Api";

export default class UserAuth_Api extends Model_Api {
  static self = new UserAuth_Api();

  protected handleErrors(
    error: AxiosError | Error | string | any
  ): IDataUserLoginErrors {
    const resp: IDataErrors<IDataUserLoginErrors> = this.formatErrors(error);
    const errors: IDataUserLoginErrors = resp.errors;
    const hookErrors: IHookFormUserLogin[] = [];

    Object.keys(errors).forEach((key) => {
      const email = key as UserLoginFields;
      const item: IHookFormUserLogin = {
        type: "manual",
        email: email,
        message: errors[key][0],
      };
      hookErrors.push(item);
    });

    errors.hookForm = hookErrors;

    return errors;
  }

  static async login(data: IDataUserLogin) {
    try {
      return (await Api.post(Endpoints.User.login, data)).data;
    } catch (error: AxiosError | any) {
      console.log("error: Create User", error.response?.data);
      throw error.response?.data.errors || error;
    }
  }

  static async logout() {
    try {
      return (await Api.post(Endpoints.User.logout)).data;
    } catch (error: AxiosError | any) {
      console.log("error: Logout", error.response?.data);
      throw error.response?.data.errors || error;
    }
  }

  static async isUserValid() {
    try {
      return (await Api.get(Endpoints.User.me)).data;
    } catch (error: AxiosError | any) {
      console.log("error: Create User", error.response?.data);
      throw error.response?.data.errors || error;
    }
  }
}
