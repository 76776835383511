import "../fake-db";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { Store } from "./redux/Store";
import routes from "./routes";
import { PrimeReactProvider, addLocale, locale } from "primereact/api";
import "app/theme/primeReactCustomTheme/theme.css";
import ptBr from "app/utils/primeReact/locales/pt-br";
import { ToastProvider } from "./hooks/useToast";

addLocale("pt-br", ptBr);
locale("pt-br");

const App = () => {
  const content = useRoutes(routes);

  const value = {
    locale: "pt-br",
  };

  return (
    <PrimeReactProvider value={value}>
      <Provider store={Store}>
        <SettingsProvider>
          <ToastProvider>
            <MatxTheme>
              <AuthProvider>{content}</AuthProvider>
            </MatxTheme>
          </ToastProvider>
        </SettingsProvider>
      </Provider>
    </PrimeReactProvider>
  );
};

export default App;
