import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { IToast } from "app/types/IToast";

const Toast = (props: IToast) => {
  const [open, setOpen] = React.useState<boolean>(props.open || false);

  React.useEffect(() => setOpen(props.open || false), [props.open]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    if (props.onClose) props.onClose();
  };

  return (
    <Snackbar
      open={open}
      sx={{ maxWidth: 350 }}
      autoHideDuration={props?.duration || 5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={{ width: "100%", opacity: "1 !important" }}
        variant="filled"
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
