import { Theme } from "@mui/material/styles";

export default function MuiCssBaseline(theme: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: theme.palette.primary.main,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: 7,
            height: 7,
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: theme.palette.primary.dark,
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: theme.palette.primary.dark,
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: theme.palette.primary.dark,
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  };
}
