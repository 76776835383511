import { Theme } from "@mui/material";

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        root: {},
      },
    },
  };
}
