import { styled } from "@mui/system";
import DotsLoading from "./icons/DotsLoading";

const StyledLoading = styled("div")(() => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    width: "auto",
    height: "25px",
  },
  "& .circleProgress": {
    position: "absolute",
    left: -7,
    right: 0,
    top: "calc(50% - 25px)",
  },
}));

const Loading = () => {
  return (
    <StyledLoading>
      <DotsLoading className="circleProgress" />
    </StyledLoading>
  );
};

export default Loading;
