const Endpoints = {
  User: {
    main: "/user",
    login: "/auth",
    logout: "/user/logout",
    me: "/user/me",
    forgot: "/reset-password",
    reset: "/confirm-reset-password",
    verifyCode: "/verify-code-password",
    profile: "/profile",
  },
  Store: {
    main: "/lojas",
  },
  Faq: {
    main: "faq",
  },
  Prize: {
    main: "/premios",
  },
  Benefit: {
    main: "/beneficios",
  },
  Winner: {
    main: "/ganhadores",
  },
  Screen: {
    main: "/telas",
  },
  Client: {
    main: "/clientes",
  },
  Menu: {
    main: "/menu",
  },
  Offer: {
    main: "/oferta",
  },
  CpfSegment: {
    main: "/segmentos",
  },
  Message: {
    main: "/mensagens",
  },
};

export default Endpoints;
