import { authRoles } from "../../auth/authRoles";
import {
  ProfileForm,
  BenefitForm,
  Benefits,
  ClientForm,
  Clients,
  Faq,
  FaqForm,
  Home,
  Menu,
  OfferForm,
  Offers,
  PrizeForm,
  Prizes,
  ScreenForm,
  Screens,
  Stores,
  StoresForm,
  Users,
  UsersForm,
  Winners,
  Messages,
  MessageForm,
} from "./routesImports";

const managementRoutes = [
  { path: "/home", element: <Home />, auth: authRoles.guest },

  // Perfil
  { path: "/perfil", element: <ProfileForm />, auth: authRoles.admin },

  // Usuários
  { path: "/usuarios", element: <Users />, auth: authRoles.admin },
  { path: "/usuarios/novo", element: <UsersForm />, auth: authRoles.admin },
  { path: "/usuarios/:id", element: <UsersForm />, auth: authRoles.admin },

  // Lojas
  { path: "/lojas", element: <Stores />, auth: authRoles.admin },
  { path: "/lojas/novo", element: <StoresForm />, auth: authRoles.admin },
  { path: "/lojas/:id", element: <StoresForm />, auth: authRoles.admin },

  // Faq
  { path: "/faqs", element: <Faq />, auth: authRoles.admin },
  { path: "/faqs/novo", element: <FaqForm />, auth: authRoles.admin },
  { path: "/faqs/:id", element: <FaqForm />, auth: authRoles.admin },

  // Prêmios
  { path: "/premios", element: <Prizes />, auth: authRoles.admin },
  { path: "/premios/novo", element: <PrizeForm />, auth: authRoles.admin },
  { path: "/premios/:id", element: <PrizeForm />, auth: authRoles.admin },

  // Benefícios
  { path: "/beneficios", element: <Benefits />, auth: authRoles.admin },
  { path: "/beneficios/novo", element: <BenefitForm />, auth: authRoles.admin },
  { path: "/beneficios/:id", element: <BenefitForm />, auth: authRoles.admin },

  // Ganhadores
  { path: "/ganhadores", element: <Winners />, auth: authRoles.admin },

  // Telas
  { path: "/telas", element: <Screens />, auth: authRoles.admin },
  { path: "/telas/novo", element: <ScreenForm />, auth: authRoles.admin },
  { path: "/telas/:id", element: <ScreenForm />, auth: authRoles.admin },

  // Clientes
  { path: "/clientes", element: <Clients />, auth: authRoles.admin },
  { path: "/clientes/:id", element: <ClientForm />, auth: authRoles.admin },

  // Menu Principal
  {
    path: "/menu-principal/",
    element: <Menu key="principal" type="principal" />,
    auth: authRoles.admin,
  },
  // Menu footer
  {
    path: "/menu-footer/",
    element: <Menu key="footer" type="footer" />,
    auth: authRoles.admin,
  },

  // Ofertas
  { path: "/ofertas", element: <Offers />, auth: authRoles.admin },
  { path: "/ofertas/:id", element: <OfferForm />, auth: authRoles.admin },

  // Mensagens
  { path: "/mensagens", element: <Messages />, auth: authRoles.admin },
  { path: "/mensagens/novo", element: <MessageForm />, auth: authRoles.admin },
  { path: "/mensagens/:id", element: <MessageForm />, auth: authRoles.admin },
];

export default managementRoutes;
