import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </StyledEngineProvider>
);
