import {
  ERROR,
  INFO,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARNING,
} from "./setupColors";

// palette values for light mode
const lightModePalette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  text: {
    primary: "rgba(12,39,73,0.87)", // Cor correspondente ao texto principal (usada no header e nos botões)
    secondary: "rgba(12,39,73,0.6)", // Cor secundária de texto (pode ser usada em outros elementos de texto)
    disabled: "rgba(12,39,73,0.38)", // Cor de texto para elementos desativados
  },
  error: ERROR,
  warning: WARNING,
  info: INFO,
  success: SUCCESS,
  divider: "rgba(12,39,73,0.12)", // Cor do divisor (por exemplo, linha divisória)
  background: {
    default: "#FFFFFF", // Cor de fundo padrão (por exemplo, fundo de página)
    paper: "#FFFFFF", // Cor de fundo para papel (por exemplo, fundo de um cartão)
  },
};

export default lightModePalette;
