import {
  ERROR,
  INFO,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARNING,
} from "./setupColors";

// palette values for dark mode
const darkModePalette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  text: {
    primary: "rgba(255,255,255,0.87)",
    secondary: "rgba(197,197,197,0.6)",
    disabled: "rgba(103,103,103,0.38)",
  },
  error: ERROR,
  warning: WARNING,
  info: INFO,
  success: SUCCESS,
  divider: "rgba(212,210,210,0.12)",
  background: {
    default: "#1c1c1c",
    paper: "#262626",
  },
  hover_color: {
    main: "#262626",
  },
  text_color: {
    soft: "#8696A0",
    teste: "#222333",
  },
};

export default darkModePalette;
