import Toast from "app/components/Toast";
import { IToast } from "app/types/IToast";
import React, { createContext, useContext, useState } from "react";

interface ToastContextProps {
  showToast: (data: IToast) => void;
  hideToast: () => void;
}

// Create the toast context
const ToastContext = createContext<ToastContextProps | null>(null);

const initialToast: IToast = {
  open: false,
  message: "",
  severity: "success",
};

// Create a provider component for the toast context
export const ToastProvider = ({ children }) => {
  const [toast, setToast] = React.useState<IToast>(initialToast);

  const showToast = (data: IToast) => {
    setToast(data);
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, open: false }));
  };

  const value: ToastContextProps = {
    showToast,
    hideToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toast
        open={toast.open}
        onClose={hideToast}
        severity={toast.severity}
        message={toast.message}
        duration={toast?.duration}
      />
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
